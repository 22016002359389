import api from "../../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import * as globalComponents from "../../../../../components";
import ListEntry from "../../../components/ListEntry";

import "../../styles.css";

export default function ProfessionalOrientations() {
  const { accessType } = useParams();
  const dir = [
    {
      title: "Serviços Online",
      url: `/${accessType}/servicos-online`,
    },
  ];
  const [data, setData] = useState([]);

  useEffect(() => {
    const additionalLinks = [
      // {
      //   Titulo: "Emissão de Carteira Digital",
      //   Subtitulo: "Para os profissionais com registro ativo no Crefito-2",
      //   Ordem: 479,
      //   href: `/${accessType}/servicos-online/orientacoes-profissionais/emissao-carteira-pvc`,
      // },
    ];

    async function fetchData() {
      await api
        .get("/profissional/orientacoes")
        .then((response) => {
          let links = [...response.data, ...additionalLinks];
          links = links.sort((a, b) => a.Ordem - b.Ordem).reverse();
          setData(links);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [accessType]);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs
        title="Orientações para Profissionais"
        dir={dir}
      />

      <div className="container">
        <div className="title__publications">
          <strong>Orientações para Profissionais</strong>

          {data.map((value, index) => (
            <ListEntry
              key={index}
              title={value.Titulo}
              call={value.Subtitulo}
              href={
                value.href
                  ? value.href
                  : `/${accessType}/servicos-online/orientacoes-profissionais/${value.Titulo.toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]|/g, "")
                      .replace(/[^a-zA-Z0-9\s]/g, "")
                      .replace(/ /g, "_")}_${value.Codigo}`
              }
            />
          ))}
        </div>
      </div>

      <globalComponents.Footer />
    </>
  );
}
