import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
// import { useParams } from "react-router-dom";

import "./styles.css";

export default function SocialNetworks() {
  // const { accessType } = useParams();s

  return (
    <div className="redes__sociais">
      {/* <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/${accessType}/fale_conosco`}
      >
        <div className="rede">
          <span className="material-icons">campaign</span>
        </div>
      </a> */}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.linkedin.com/company/crefito-2"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faLinkedin} />
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/crefito2"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faFacebook} />
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/crefito2/"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faInstagram} />
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.twitter.com/crefito2"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faTwitter} />
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.youtube.com/channel/UCvqZh_pif-knE15ymgM9Vxg"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faYoutube} />
        </div>
      </a>

      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.tiktok.com/@crefito2"
      >
        <div className="rede">
          <FontAwesomeIcon icon={faTiktok} />
        </div>
      </a>
    </div>
  );
}
