import api from "../../../../services/api";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import * as globalComponents from "../../../../components";
import * as localComponents from "../../components";

import "./styles.css";
import "../styles.css";

import imagePortal from "./images/portal.png";

export default function Bids() {
  const { accessType } = useParams();
  const [data, setData] = useState({
    resume:
      "A Comissão Permanente de Licitação do Conselho é responsável por preparar, analisar, avaliar e julgar os processos licitatórios",
    inProgress: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
    dispensation: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
    closed: [
      {
        Codigo: null,
        Titulo: null,
        Chamada: null,
        Data_Expiracao: null,
        Ordem: null,
      },
    ],
  });

  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      await api
        .get("/licitacoes")
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <globalComponents.Header />

      <globalComponents.Breadcrumbs title="Licitações" dir={[]} />

      <div className="container">
        <globalComponents.PageTitle title="Licitações" span={data.resume} />

        <Accordion className="accordion" allowZeroExpanded={true}>
          <AccordionItem className="card">
            <AccordionItemHeading
              className="card-header"
              style={{ background: "var(--secondary-color)" }}
            >
              <AccordionItemButton className="card-header-button">
                1. PCA - PLANO DE CONTRATAÇÃO ANUAL (
                <a
                  className="link_header_sanfona"
                  rel="noopener noreferrer"
                  href="https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2022/Decreto/D10947.htm"
                  target="_blank"
                >
                  Decreto nº 10.947, de 25 de janeiro de 2022
                </a>
                )
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body">
              <p>
                O PCA - PLANO DE CONTRATAÇÃO ANUAL (
                <a
                  className="link_header_sanfona"
                  rel="noopener noreferrer"
                  href="https://www.planalto.gov.br/ccivil_03/_ato2019-2022/2022/Decreto/D10947.htm"
                  target="_blank"
                >
                  Decreto nº 10.947, de 25 de janeiro de 2022
                </a>
                ) é o instrumento estratégico usado pelos órgãos e entidades da
                Administração Pública para planejar e organizar suas
                contratações ao longo do ano. O Conselho Regional de
                Fisioterapia e Terapia Ocupacional da Segunda Região -
                CREFITO-2, aderiu o PCA desde sua criação, e o cidadão poderá
                pesquisar nesta ferramenta o Plano de Contratação Anual da
                Autarquia, conforme orientação abaixo.
              </p>

              <a
                className="link_header_sanfona"
                rel="noopener noreferrer"
                href="https://pncp.gov.br/app/pca/29991262000147/2025"
                target="_blank"
              >
                Acesso ao Portal
              </a>

              <img
                src={imagePortal}
                alt="Website do Portal"
                style={{
                  margin: "35px auto 0",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              />
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem className="card">
            <AccordionItemHeading
              className="card-header"
              style={{ background: "var(--secondary-color-light)" }}
            >
              <AccordionItemButton className="card-header-button">
                2. LICITAÇÃO (
                <a
                  className="link_header_sanfona"
                  rel="noopener noreferrer"
                  href="https://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2021/Lei/L14133.htm"
                  target="_blank"
                >
                  Lei Federal 14.133/2021
                </a>
                )
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body">
              <h2>2.1 Em andamento</h2>
              <div className="title_licitacao__andamento">
                <strong>Licitações em andamento</strong>

                {data.inProgress.map((value, index) => (
                  <localComponents.InProgressCard
                    key={index}
                    title={value.Titulo}
                    span={value.Chamada}
                    href={`/${accessType}/licitacoes/${value.Codigo}`}
                  />
                ))}
              </div>

              <br />

              <h2>2.2 Encerradas</h2>
              <p>
                Todos os processos de licitações do Conselho Regional de
                Fisioterapia e Terapia Ocupacional da Segunda Região -
                CREFITO-2, com status ENCERRADA, ficam à disposição para
                consulta no Portal Nacional de Contratações Públicas.
              </p>
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem className="card">
            <AccordionItemHeading
              className="card-header"
              style={{ background: "var(--secondary-color)" }}
            >
              <AccordionItemButton className="card-header-button">
                3. PNCP - PORTAL NACIONAL DE CONTRATAÇÕES PÚBLICAS (
                <a
                  className="link_header_sanfona"
                  rel="noopener noreferrer"
                  href="https://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2021/Lei/L14133.htm"
                  target="_blank"
                >
                  Art.174 da Lei Federal nº14.133/2021
                </a>
                )
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="card-body">
              <p>
                O{" "}
                <strong>Portal Nacional de Contratações Públicas (PNCP)</strong>{" "}
                foi instituído pela <strong>Lei Federal nº 14.133/2021</strong>{" "}
                como o ambiente eletrônico oficial para a divulgação
                centralizada e obrigatória de atos relacionados a{" "}
                <strong>licitações e contratos administrativos.</strong> Seu
                principal objetivo é garantir{" "}
                <strong>transparência, acessibilidade e eficiência</strong> na
                gestão das contratações públicas, reunindo em um único local
                informações como{" "}
                <strong>
                  editais, contratos, atas de registro de preços e sanções
                  aplicadas.
                </strong>{" "}
                Seu uso é <strong>obrigatório</strong> para os órgãos e
                entidades que adotam a <strong>Nova Lei de Licitações</strong>,
                consolidando-se como uma ferramenta essencial para a
                modernização e fiscalização dos processos administrativos.
              </p>

              <p>
                <strong>Como pesquisar no PNCP – Passo a Passo</strong>
              </p>

              <p>
                <ol style={{ marginLeft: "15px" }}>
                  <li>
                    Acesse o site oficial do PNCP, pelo link:
                    <a
                      rel="noopener noreferrer"
                      href="https://www.gov.br/pncp"
                      target="_blank"
                    >
                      https://www.gov.br/pncp
                    </a>
                  </li>
                  <li>
                    Escolha a funcionalidade desejada:
                    <ul>
                      <li>
                        ✅ Painel de Licitações - Para buscar editais e
                        processos licitatórios.
                      </li>
                      <li>
                        ✅ Contratos e Atas de Registro de Preço - Para
                        consultar contratos firmados e atas publicadas.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Utilize os filtros de pesquisa
                    <br />
                    Os filtros ajudam a refinar a busca. Você pode pesquisar
                    por:
                    <br />
                    🔹 Órgão ou entidade responsável
                    <p style={{ marginLeft: "50px" }}>
                      Palavra-chave - Digite: Cons Reg de Fisioterapia
                      <br />
                      UFs - Digite: RJ
                    </p>
                    Após localizar o processo desejado, clique sobre ele para
                    visualizar documentos, prazos, valores e demais informações.
                  </li>
                  <li>
                    Baixe documentos e acompanhe atualizações: O portal permite
                    o download de arquivos e o acompanhamento de processos em
                    andamento.
                  </li>
                </ol>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>

        {/* <a
          className="link_header_sanfona"
          rel="noopener noreferrer"
          href="https://pncp.gov.br/app/pca/29991262000147/2025"
          target="_blank"
        >
          Link do PCA - CREFITO-2 - 2025 publicado
        </a>

        <br />

        <a
          className="link_header_sanfona"
          rel="noopener noreferrer"
          href="https://crefito2.go2webdbm.com.br/PORTARIA_CREFITO-2_007-2023.pdf"
          target="_blank"
        >
          PORTARIA CREFITO-2 Nº 07, 09 DE MARÇO DE 2023
        </a> */}

        {/* <div className="title_licitacao__dispensadas">
          <strong>Dispensa de licitação</strong>

          {data.dispensation.map((value, index) => (
            <localComponents.DispensationCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/licitacoes/${value.Codigo}`}
            />
          ))}
        </div>

        <div className="title_licitacao__encerradas">
          <strong>Licitações encerradas</strong>

          {data.closed.map((value, index) => (
            <localComponents.ClosedCard
              key={index}
              title={value.Titulo}
              span={value.Chamada}
              href={`/${accessType}/licitacoes/${value.Codigo}`}
            />
          ))}
        </div> */}
      </div>

      <globalComponents.Footer />
    </>
  );
}
